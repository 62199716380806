import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import PortableText from "../components/portableText/portableText";
import CountryCodes from "../components/global/countryCodes";

function OfficeCard(props) {
  return (
    <div className="card text-white bg-dark mb-3" style="max-width: 18rem;">
      <div className="card-header">{props.location}</div>
      <div className="card-body">
        <p className="card-text">
          {props.address}
          <br />
          <i className="bi bi-phone"></i>
          {props.mobile}
        </p>
      </div>
    </div>
  );
}

export default function Contact() {
  return (
    <>
      <Layout>
        <SEO />
        <div className="contact-container py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 d-flex align-items-center">
                <div className="text-container">
                  <h1 className="text-light mb-5">
                    Send us your queries and our team will get in touch with you
                    soon.
                  </h1>
                  {/* <h2 style={{ color: "#a1a1a1" }}>Our Offices</h2>
                  <div className="d-flex"></div> */}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="card">
                  <form>
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div className="form-group">
                          <label forHtml="email" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            aria-describedby="email"
                            placeholder="name@example.com"
                          ></input>
                        </div>
                      </div>
                      <div className="col-12 mb-4">
                        <div className="form-group">
                          <label forHtml="name" className="form-label">
                            Name
                          </label>
                          <input
                            type="name"
                            className="form-control"
                            id="name"
                            aria-describedby="name"
                            placeholder="Full Name"
                          ></input>
                        </div>
                      </div>
                      <div className="col-4 mb-4">
                        <div className="form-group">
                          <label forHtml="country" className="form-label">
                            Country
                          </label>
                          <select
                            className="form-select"
                            aria-label="Country Code"
                            id="country"
                          >
                            {CountryCodes}
                          </select>
                        </div>
                      </div>
                      <div className="col-8 mb-4">
                        <div className="form-group">
                          <label forHtml="mobile" className="form-label">
                            Mobile
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="mobile"
                            aria-describedby="mobile"
                            placeholder="xxxxx-xxxxx"
                          ></input>
                        </div>
                      </div>
                      <div className="col-12 mb-5">
                        <label forHtml="message" className="form-label">
                          Message
                        </label>
                        <textarea
                          className="form-control"
                          id="message"
                          rows="3"
                        ></textarea>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-dark w-100">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="me-2">Send Message</span>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path>
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>

      <style jsx>
        {`
          .contact-container {
            background-color: #000000;
          }

          .contact-container .card {
            padding: 1.5rem 1rem;
            max-width: 22rem;
            margin: 5rem auto;
          }

          @media only screen and (max-width: 768px) {
            .contact-container .text-container {
              margin-top: 6rem;
            }

            .contact-container .card {
              margin: 2rem auto;
            }
          }
        `}
      </style>
    </>
  );
}
